import { initialState } from "./initialState";
import * as types from "../actions/actionTypes";

function evaluateStats(stats, guesseslength, input, guessdigit) {
  var allstats = [...stats];
  if (input === guessdigit.toString().replaceAll(",", "")) {
    allstats[stats.length] = guesseslength + 1;
    window.localStorage.setItem("stats", JSON.stringify(allstats));
  }
  return allstats;
}

export default function statsReducer(state = initialState.stats, action) {
  switch (action.type) {
    case types.EVALUATE_STATS:
      return evaluateStats(
        state,
        action.guesseslength,
        action.input,
        action.guessdigit
      );
    default:
      return state;
  }
}
