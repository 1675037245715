import React from "react";
import GameForm from "./game/gameForm";
import MainHeader from "./header/mainHeader";

function App() {
  return (
    <div className="main-app">
      <MainHeader></MainHeader>
      {window.innerHeight < 550 ? (
        <GameForm style={{ maxHeight: "550px" }}></GameForm>
      ) : (
        <GameForm style={{ maxHeight: "800px" }}></GameForm>
      )}
    </div>
  );
}

export default App;
