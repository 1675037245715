import { Fireworks } from "fireworks-js/dist/react";
import React from "react";

class Firework extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      options: {
        speed: 3,
      },
      style: {
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        position: "fixed",
      },
    };
  }

  componentDidMount() {
    setTimeout(
      function () {
        //Start the timer
        this.setState({ render: false }); //After 4 seconds, set render to true
      }.bind(this),
      4000
    );
  }

  render() {
    let renderContainer = true;
    if (this.state.render) {
      renderContainer = (
        <Fireworks options={this.state.options} style={this.state.style} />
      );
    }
    return renderContainer;
  }
}

export default Firework;
