// Import React (Mandatory Step).
import React from "react";
import PropTypes from "prop-types";
import NumberButton from "./numberButton";

class NumberKeyPad extends React.Component {
  render() {
    return (
      <div className="number-keypad">
        <div className="first-row">
          <NumberButton
            disabled={this.props.disabled[1]}
            value="1"
            actions={this.props.actions}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[2]}
            value="2"
            actions={this.props.actions}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[3]}
            value="3"
            actions={this.props.actions}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[4]}
            value="4"
            actions={this.props.actions}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[5]}
            value="5"
            actions={this.props.actions}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[6]}
            value="6"
            actions={this.props.actions}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[7]}
            value="7"
            actions={this.props.actions}
          ></NumberButton>
        </div>
        <div className="second-row">
          <NumberButton
            value="Enter"
            disabled={this.props.disabledenter}
            actions={this.props.actions}
            submit={this.props.submit}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[8]}
            value="8"
            actions={this.props.actions}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[9]}
            value="9"
            actions={this.props.actions}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[0]}
            value="0"
            actions={this.props.actions}
          ></NumberButton>
          <NumberButton
            disabled={this.props.disabled[10]}
            value="Back"
            actions={this.props.actions}
          ></NumberButton>
        </div>
      </div>
    );
  }
}

export default NumberKeyPad;

NumberKeyPad.propTypes = {
  input: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.array.isRequired,
  disabledenter: PropTypes.bool,
};
