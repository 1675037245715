import React from "react";
import { connect } from "react-redux";
import * as guessActions from "../../redux/actions/guessActions";
import * as inputActions from "../../redux/actions/inputActions";
import * as statsActions from "../../redux/actions/statsActions";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { bindActionCreators } from "redux";
import InputRow from "./inputRow";
import NumberKeyPad from "./numberKeypad";
import ResultTable from "./resultTable";
import WinningPopup from "../header/winningPopup";
import Fireworks from "./fireworksSet";

class EnterGuess extends React.Component {
  constructor(props) {
    super(props);
    this.triggerWinningPopupClick = this.triggerWinningClick.bind(this);
    this.handleSubmitClick = this.handleSubmit.bind(this);
    this.state = {
      guess: {
        value: "",
        result: {
          victory: false,
        },
      },
      triggerWinningPopup: true,
    };
  }

  triggerWinningClick(triggervalue) {
    this.setState({ triggerWinningPopup: triggervalue });
  }

  handleSubmit = () => {
    const guess = { ...this.state.guess, value: this.props.input };
    this.setState({ guess: guess });
    this.props.actions.enterGuess(guess, this.props.guessdigit);

    this.props.actions.evaluateStats(
      this.props.guesses.length,
      this.props.input,
      this.props.guessdigit
    );
    this.props.actions.changeInput("");
  };

  handleKeyPress = (e) => {
    if (
      "0123456789".includes(e.key) &&
      !this.props.input.includes(e.key) &&
      this.props.input.length < 4
    ) {
      this.props.actions.changeInput(e.key);
    } else if (e.key === "Backspace") {
      this.props.actions.changeInput("Back");
    } else if (
      e.key === "Enter" &&
      this.props.input.length === 4 &&
      !this.props.guesses[this.props.guesses.length - 1]?.result.victory
    ) {
      const guess = { ...this.state.guess, value: this.props.input };
      this.setState({ guess: guess });
      this.props.actions.enterGuess(guess, this.props.guessdigit);

      this.props.actions.evaluateStats(
        this.props.guesses.length,
        this.props.input,
        this.props.guessdigit
      );
      this.props.actions.changeInput("");
    }
  };

  checkDisabledForEnter() {
    return (
      this.props.input.length < 4 ||
      this.props.guesses[this.props.guesses.length - 1]?.result.victory
    );
  }

  evaluateDisabled() {
    var aDisabled = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    if (
      this.props.victory ||
      this.props.guesses[this.props.guesses.length - 1]?.result.victory
    )
      aDisabled = [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ];
    else if (this.props.input.length === 4)
      aDisabled = [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
      ];
    else if (this.props.input.length === 0) aDisabled[10] = true;
    else
      this.props.input.split("").forEach((element) => {
        aDisabled[element] = true;
      });
    return aDisabled;
  }

  render() {
    return (
      <div className="game" tabIndex="1" onKeyDown={this.handleKeyPress}>
        {/* <div className = "correctnumber">Correct Number (hover)</div>
        <div className = "hide"><h3>{this.props.guessdigit}</h3></div> */}
        <div className="table-holder">
          <ResultTable guesses={this.props.guesses}></ResultTable>
        </div>

        <InputRow input={this.props.input}></InputRow>
        <NumberKeyPad
          disabled={this.evaluateDisabled()}
          disabledenter={this.checkDisabledForEnter()}
          input={this.props.input}
          actions={this.props.actions}
          submit={() => this.handleSubmitClick()}
        ></NumberKeyPad>
        {(() => {
          if (
            this.props.guesses[this.props.guesses.length - 1]?.result.victory
          ) {
            return (
              <div>
                <CSSTransition
                  classNames="fade"
                  in={true}
                  appear={true}
                  timeout={1300}
                >
                  <WinningPopup
                    trigger={this.state.triggerWinningPopup}
                    setTrigger={this.triggerWinningPopupClick}
                    stats={this.props.stats}
                  ></WinningPopup>
                </CSSTransition>
                <CSSTransition
                  classNames="fade"
                  in={true}
                  appear={true}
                  timeout={1300}
                >
                  <Fireworks enabled="true"></Fireworks>
                </CSSTransition>
              </div>
            );
          } else {
            return null;
          }
        })()}
      </div>
    );
  }
}

EnterGuess.propTypes = {
  guesses: PropTypes.array.isRequired,
  input: PropTypes.string,
  guessdigit: PropTypes.array.isRequired,
  victory: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.array,
  stats: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    guesses: state.guesses,
    input: state.input,
    guessdigit: state.guessdigit,
    victory: state.victory,
    disabled: state.disabled,
    stats: state.stats,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      enterGuess: bindActionCreators(guessActions.enterGuess, dispatch),
      changeInput: bindActionCreators(inputActions.changeInput, dispatch),
      evaluateStats: bindActionCreators(statsActions.evaluateStats, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterGuess);
