import * as types from "../actions/actionTypes";
import { initialState } from "./initialState";

function evaluateInput(input, state) {
  var result = "";
  if (input.trim() !== "Back" && input.trim() !== "") {
    result = state + input.trim();
  } else if (input.trim() === "") {
    result = input.trim();
  } else {
    result = state.slice(0, -1);
  }
  window.localStorage.setItem("saved-input", result);
  return result;
}

export default function inputReducer(state = initialState.input, action) {
  switch (action.type) {
    case types.CHANGE_INPUT:
      return evaluateInput(action.input, state);
    default:
      return state;
  }
}
