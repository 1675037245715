// Import React (Mandatory Step).
import React from "react";
import PropTypes from "prop-types";

class NumberButton extends React.Component {
  handleButton = (event) => {
    event.preventDefault();
    this.props.actions.changeInput(event.target.textContent);
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.disabled !== nextProps.disabled) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <button
        className={
          this.props.value === "Back"
            ? "button-back"
            : this.props.value === "0"
            ? "button-zero"
            : this.props.value === "Enter"
            ? "button-enter"
            : "button"
        }
        key={this.props.value}
        disabled={this.props.disabled}
        onClick={
          this.props.value !== "Enter" ? this.handleButton : this.props.submit
        }
      >
        {this.props.value}
      </button>
    );
  }
}

export default NumberButton;

NumberButton.propTypes = {
  value: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  disabledenter: PropTypes.bool,
  submit: PropTypes.func,
};
