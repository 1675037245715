import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class WinningPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupTriggered: true,
      statistics: {
        total: 0,
        best: 0,
        average: 0,
        less4: 0,
        five: 0,
        six: 0,
        seven: 0,
        eight: 0,
        nine: 0,
        ten: 0,
        eleven: 0,
        more12: 0,
        newbest: false,
        less4won: false,
        fivewon: false,
        sixwon: false,
        sevenwon: false,
        eightwon: false,
        ninewon: false,
        tenwon: false,
        evelenwon: false,
        more12won: false,
        biggeststatswintotal: 0,
      },
      stats: [],
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (JSON.stringify(nextProps.stats) !== JSON.stringify(state.stats)) {
      var updateStats = { ...state.statistics };
      updateStats.total = nextProps.stats.length;
      updateStats.best = Math.min(...nextProps.stats);
      if (
        nextProps.guesses.length > 0 &&
        nextProps.guesses[nextProps.guesses.length - 1].result.victory &&
        nextProps.guesses.length <= updateStats.best
      )
        updateStats.newbest = true;
      var total = 0;
      var less4total = 0;
      var fivetotal = 0;
      var sixtotal = 0;
      var seventotal = 0;
      var eighttotal = 0;
      var ninetotal = 0;
      var tentotal = 0;
      var eleventotal = 0;
      var more12total = 0;
      for (var i = 0; i < nextProps.stats.length; i++) {
        total += nextProps.stats[i];
        if (nextProps.stats[i] <= 4) less4total++;
        if (nextProps.stats[i] === 5) fivetotal++;
        if (nextProps.stats[i] === 6) sixtotal++;
        if (nextProps.stats[i] === 7) seventotal++;
        if (nextProps.stats[i] === 8) eighttotal++;
        if (nextProps.stats[i] === 9) ninetotal++;
        if (nextProps.stats[i] === 10) tentotal++;
        if (nextProps.stats[i] === 11) eleventotal++;
        if (nextProps.stats[i] >= 12) more12total++;
      }
      if (
        nextProps.guesses.length > 0 &&
        nextProps.guesses[nextProps.guesses.length - 1].result.victory
      ) {
        if (nextProps.guesses.length <= 4) updateStats.less4won = true;
        if (nextProps.guesses.length === 5) updateStats.fivewon = true;
        if (nextProps.guesses.length === 6) updateStats.sixwon = true;
        if (nextProps.guesses.length === 7) updateStats.sevenwon = true;
        if (nextProps.guesses.length === 8) updateStats.eightwon = true;
        if (nextProps.guesses.length === 9) updateStats.ninewon = true;
        if (nextProps.guesses.length === 10) updateStats.tenwon = true;
        if (nextProps.guesses.length === 11) updateStats.evelenwon = true;
        if (nextProps.guesses.length >= 12) updateStats.more12won = true;
      }
      updateStats.biggeststatswintotal = Math.max(
        less4total,
        fivetotal,
        sixtotal,
        seventotal,
        eighttotal,
        ninetotal,
        tentotal,
        eleventotal,
        more12total
      );
      updateStats.average =
        nextProps.stats.length > 0
          ? (total / nextProps.stats.length).toFixed(1)
          : 0;
      updateStats.less4 = less4total;
      updateStats.five = fivetotal;
      updateStats.six = sixtotal;
      updateStats.seven = seventotal;
      updateStats.eight = eighttotal;
      updateStats.nine = ninetotal;
      updateStats.ten = tentotal;
      updateStats.eleven = eleventotal;
      updateStats.more12 = more12total;
      return { stats: nextProps.stats, statistics: updateStats };
    } else return null;
  }

  render() {
    // const time = new Date();
    // time.setSeconds(time.getSeconds() + 3599); // 1 hour
    return this.props.trigger && this.state.popupTriggered ? (
      <div className="popup">
        <div className="popup-inner-stats">
          <button
            className="x-btn"
            onClick={() => this.props.setTrigger(false)}
          >
            X
          </button>
          <p style={{ fontWeight: "bold" }}>Statistics</p>
          <div
            className="stat-holder"
            style={{ textAlign: "left", display: "flex" }}
          >
            <p>Games Played: {this.state.statistics.total}</p>
          </div>
          <p style={{ fontWeight: "bold" }}>Guess Distribution</p>
          <p
            style={{
              textAlign: "left",
              color: this.state.statistics.newbest ? "green" : "black",
            }}
          >
            Personal Best:{" "}
            {this.state.statistics.best === Infinity
              ? "Play a game first"
              : this.state.statistics.best}
          </p>
          <p style={{ textAlign: "left" }}>
            Average: {this.state.statistics.average}
          </p>
          <div className="stat-holder-text">
            <div className="stat-tries">
              <p>{"<"}4:</p>
              <p>5:</p>
              <p>6:</p>
              <p>7:</p>
              <p>8:</p>
              <p>9:</p>
              <p>10:</p>
              <p>11:</p>
              <p>{">"}12:</p>
            </div>
            <div className="stat-bars">
              <div
                className="stat-individual"
                style={{
                  backgroundColor: this.state.statistics.less4won
                    ? "green"
                    : "#666666",
                  width:
                    this.state.statistics.total === 0
                      ? "7%"
                      : this.props.guesses.length <= 4 &&
                        this.state.statistics.biggeststatswintotal === 1
                      ? (this.state.statistics.less4 /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        10 +
                        "%"
                      : (this.state.statistics.less4 /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        7 +
                        "%",
                }}
              >
                {this.state.statistics.less4}
                {this.props.guesses.length !== 0 &&
                this.props.guesses.length <= 4 &&
                this.props.guesses[this.props.guesses.length - 1].result
                  .victory ? (
                  <span>
                    {" ("}
                    {this.props.guesses.length}
                    {")"}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                className="stat-individual"
                style={{
                  backgroundColor: this.state.statistics.fivewon
                    ? "green"
                    : "#666666",
                  width:
                    this.state.statistics.total === 0
                      ? "7%"
                      : (this.state.statistics.five /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        7 +
                        "%",
                }}
              >
                {this.state.statistics.five}
              </div>
              <div
                className="stat-individual"
                style={{
                  backgroundColor: this.state.statistics.sixwon
                    ? "green"
                    : "#666666",
                  width:
                    this.state.statistics.total === 0
                      ? "7%"
                      : (this.state.statistics.six /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        7 +
                        "%",
                }}
              >
                {this.state.statistics.six}
              </div>
              <div
                className="stat-individual"
                style={{
                  backgroundColor: this.state.statistics.sevenwon
                    ? "green"
                    : "#666666",
                  width:
                    this.state.statistics.total === 0
                      ? "7%"
                      : (this.state.statistics.seven /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        7 +
                        "%",
                }}
              >
                {this.state.statistics.seven}
              </div>
              <div
                className="stat-individual"
                style={{
                  backgroundColor: this.state.statistics.eightwon
                    ? "green"
                    : "#666666",
                  width:
                    this.state.statistics.total === 0
                      ? "7%"
                      : (this.state.statistics.eight /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        7 +
                        "%",
                }}
              >
                {this.state.statistics.eight}
              </div>
              <div
                className="stat-individual"
                style={{
                  backgroundColor: this.state.statistics.ninewon
                    ? "green"
                    : "#666666",
                  width:
                    this.state.statistics.total === 0
                      ? "7%"
                      : (this.state.statistics.nine /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        7 +
                        "%",
                }}
              >
                {this.state.statistics.nine}
              </div>
              <div
                className="stat-individual"
                style={{
                  backgroundColor: this.state.statistics.tenwon
                    ? "green"
                    : "#666666",
                  width:
                    this.state.statistics.total === 0
                      ? "7%"
                      : (this.state.statistics.ten /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        7 +
                        "%",
                }}
              >
                {this.state.statistics.ten}
              </div>
              <div
                className="stat-individual"
                style={{
                  backgroundColor: this.state.statistics.evelenwon
                    ? "green"
                    : "#666666",
                  width:
                    this.state.statistics.total === 0
                      ? "7%"
                      : (this.state.statistics.eleven /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        7 +
                        "%",
                }}
              >
                {this.state.statistics.eleven}
              </div>
              <div
                className="stat-individual"
                style={{
                  backgroundColor: this.state.statistics.more12won
                    ? "green"
                    : "#666666",
                  width:
                    this.state.statistics.total === 0
                      ? "7%"
                      : this.props.guesses.length >= 12 &&
                        this.state.statistics.biggeststatswintotal === 1
                      ? (this.state.statistics.more12 /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        10 +
                        "%"
                      : (this.state.statistics.more12 /
                          this.state.statistics.biggeststatswintotal) *
                          95 +
                        7 +
                        "%",
                }}
              >
                {this.state.statistics.more12}
                {this.props.guesses.length >= 12 &&
                this.props.guesses[this.props.guesses.length - 1].result
                  .victory ? (
                  <span>
                    {" ("}
                    {this.props.guesses.length}
                    {")"}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/* <MyTimer expiryTimestamp={time}></MyTimer> */}
        </div>
      </div>
    ) : null;
  }
}

// function MyTimer({ expiryTimestamp }) {
//     const { hours, minutes, seconds } = useTimer({
//       expiryTimestamp,
//       onExpire: () => initializeState(true),
//     });

//     return (
//       <div>
//         <h3>Next Puzzle In:</h3>
//         <h2>
//           {hours.toFixed(2)}:{minutes.toFixed(2)}:{seconds.toFixed(2)}
//         </h2>
//       </div>
//     );
// }

WinningPopup.propTypes = {
  trigger: PropTypes.bool,
  setTrigger: PropTypes.func,
  stats: PropTypes.array,
  guesses: PropTypes.array,
  // expiryTimestamp: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    stats: state.stats,
    guesses: state.guesses,
  };
}

export default connect(mapStateToProps)(WinningPopup);
