// Import React (Mandatory Step).
import React from "react";
import PropTypes from "prop-types";

class InputBox extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.input.charAt(this.props.position) !==
      nextProps.input.charAt(this.props.position)
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <input
        className="input-box"
        type="text"
        readOnly={true}
        value={this.props.input.charAt(this.props.position)}
      ></input>
    );
  }
}

export default InputBox;

InputBox.propTypes = {
  input: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
};
