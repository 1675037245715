import React from "react";
import { PropTypes } from "prop-types";
import WinningPopup from "./winningPopup";
import GameRulesPopup from "./gamerulesPopup";

class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.triggerGameRulesPopupClick = this.triggerGameRulesClick.bind(this);
    this.triggerWinningPopupClick = this.triggerWinningClick.bind(this);
    this.state = { triggerGamerules: false, triggerWinning: false };
  }

  shouldComponentUpdate(nextProps) {
    return this.props === nextProps;
  }

  triggerGameRulesClick(triggervalue) {
    this.setState({ triggerGamerules: triggervalue });
  }

  triggerWinningClick(triggervalue) {
    this.setState({ triggerWinning: triggervalue });
  }

  render() {
    return (
      <div className="main-header">
        <button
          className="btn-gamerules"
          style={{ color: "#222" }}
          onClick={() => this.triggerGameRulesClick(true)}
        >
          Rules
        </button>
        <div className="gamename">GuessDigits</div>
        <button
          className="btn-winning"
          style={{ color: "#222" }}
          onClick={() => this.triggerWinningClick(true)}
        >
          Stats
        </button>
        <GameRulesPopup
          trigger={this.state.triggerGamerules}
          setTrigger={this.triggerGameRulesPopupClick}
        ></GameRulesPopup>
        <WinningPopup
          trigger={this.state.triggerWinning}
          setTrigger={this.triggerWinningPopupClick}
        ></WinningPopup>
      </div>
    );
  }
}

MainHeader.propTypes = {
  triggerWinning: PropTypes.bool,
  triggerGamerules: PropTypes.bool,
};

export default MainHeader;
