// Import React (Mandatory Step).
import React from "react";
import PropTypes from "prop-types";
import InputBox from "./inputBox";
import { connect } from "react-redux";

const InputRow = (props) => {
  return (
    <div className="input-row">
      <div className="input-inner">
        <InputBox input={props.input} position={0}></InputBox>
        <InputBox input={props.input} position={1}></InputBox>
        <InputBox input={props.input} position={2}></InputBox>
        <InputBox input={props.input} position={3}></InputBox>
      </div>
    </div>
  );
};

InputRow.propTypes = {
  input: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    input: state.input,
  };
}

// Export Input Screen.
export default connect(mapStateToProps)(InputRow);
