import { combineReducers } from "redux";
import guesses from "./guessReducer";
import input from "./inputReducer";
import guessdigit from "./guessdigitReducer";
import victory from "./victoryReducer";
import disabled from "./disabledReducer";
import stats from "./statsReducer";

const rootReducer = combineReducers({
  guesses,
  input,
  guessdigit,
  victory,
  disabled,
  stats,
});

export default rootReducer;
