import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

class ResultTable extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.guesses !== nextProps.guesses) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Guesses</th>
            <th>Present</th>
            <th>Correct</th>
          </tr>
        </thead>
        <tbody>
          {this.props.guesses.map((guess) => {
            return (
              <tr key={guess.id}>
                <td>{guess.value}</td>
                <CSSTransition
                  classNames="fade"
                  in={true}
                  appear={true}
                  timeout={500}
                >
                  <td>{guess.result.numbers}</td>
                </CSSTransition>
                <CSSTransition
                  classNames="fade"
                  in={true}
                  appear={true}
                  timeout={1000}
                >
                  <td>{guess.result.places}</td>
                </CSSTransition>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default ResultTable;

ResultTable.propTypes = {
  guesses: PropTypes.array.isRequired,
};
