import React from "react";
import PropTypes from "prop-types";

class GameRulesPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { popupTriggered: true };
  }

  shouldComponentUpdate(nextProps) {
    return this.props.trigger !== nextProps.trigger;
  }

  render() {
    return this.props.trigger && this.state.popupTriggered ? (
      <div className="popup">
        <div className="popup-inner-rules">
          <button
            className="x-btn"
            onClick={() => this.props.setTrigger(false)}
          >
            X
          </button>
          <br></br>
          <p>
            <span className="bold-this">Guess</span> the 4{" "}
            <span className="bold-this">digits</span>, where each one is
            different
          </p>
          <p>In your guess, all digits must be different as well</p>
          <div className="hr"></div>
          <p>For example, correct digits are:</p>
          <p style={{ fontWeight: "bold" }}>7 9 3 8</p>
          <table className="table">
            <thead>
              <tr>
                <th>Guesses</th>
                <th>Present</th>
                <th>Correct</th>
              </tr>
            </thead>
            <tbody>
              <tr key="1">
                <td>9031</td>
                <td>2</td>
                <td>1</td>
              </tr>
            </tbody>
          </table>
          <div className="hr"></div>
          <div className="example-holder" style={{ display: "flex" }}>
            <div
              className="example-holder-explanation"
              style={{ textAlign: "left" }}
            >
              <p style={{ color: "#c9b458" }}>Total digits present: 2</p>
              <p style={{ letterSpacing: "5px" }}>
                <span className="present-digit">9</span>0
                <span className="present-digit">3</span>1{" "}
                <span style={{ letterSpacing: "1px" }}>----{">"}</span> 7
                <span className="present-digit">93</span>8
              </p>
              <p style={{ color: "#6aaa64" }}>
                Total digits in correct place: 1
              </p>
              <p style={{ letterSpacing: "5px" }}>
                90
                <span className="correct-digit">3</span>1{" "}
                <span style={{ letterSpacing: "1px" }}>----{">"}</span> 79
                <span className="correct-digit">3</span>8
              </p>
            </div>
            <div className="example-holder-middlespace"></div>
            <div
              className="example-holder-digits"
              style={{ textAlign: "right" }}
            ></div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

GameRulesPopup.propTypes = {
  trigger: PropTypes.bool,
  setTrigger: PropTypes.func,
};

export default GameRulesPopup;
