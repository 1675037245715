const initialState = setState(false);

function setState(RESET) {
  var fVictory = window.localStorage.getItem("victory");
  var oStats = window.localStorage.getItem("stats");
  if (
    oStats === null ||
    oStats === "" ||
    (oStats[0] !== "[" && oStats[oStats.length - 1] !== "]")
  ) {
    oStats = [];
    window.localStorage.setItem("stats", JSON.stringify(oStats));
  } else oStats = JSON.parse(window.localStorage.getItem("stats"));
  var state = initializeState(RESET, oStats);
  if (fVictory === "false") {
    state = {
      victory: false,
      guesses:
        window.localStorage.getItem("saved-guesses") === ""
          ? []
          : JSON.parse(window.localStorage.getItem("saved-guesses")),
      input: window.localStorage.getItem("saved-input"),
      guessdigit:
        JSON.parse(window.localStorage.getItem("saved-digit")) === ""
          ? randomizeNumber(true)
          : JSON.parse(window.localStorage.getItem("saved-digit")),
      disabled: [],
      stats: oStats,
    };
  } else {
    window.localStorage.setItem("saved-guesses", JSON.stringify(state.guesses));
    window.localStorage.setItem("saved-input", state.input);
    window.localStorage.setItem(
      "saved-digit",
      JSON.stringify(state.guessdigit)
    );
    window.localStorage.setItem("victory", state.victory);
  }
  return state;
}

function randomizeNumber(savetostorage) {
  var digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  var random = [];
  for (var i = 0; i < 4; i++) {
    var rand = Math.floor(Math.random() * digits.length) + 0;
    random.push(digits[rand]);
    digits.splice(rand, 1);
  }
  if (savetostorage)
    window.localStorage.setItem("saved-digit", JSON.stringify(random));
  return random;
}

export function initializeState(reset, oStats) {
  var state = {
    guesses: [],
    input: "",
    guessdigit: randomizeNumber(false),
    victory: false,
    disabled: [],
    stats: oStats,
  };
  if (reset) {
    window.localStorage.setItem("saved-guesses", JSON.stringify(state.guesses));
    window.localStorage.setItem("saved-input", state.input);
    window.localStorage.setItem(
      "saved-digit",
      JSON.stringify(state.guessdigit)
    );
    window.localStorage.setItem("victory", state.victory);
  }
  return state;
}

export { initialState };
