import * as types from "../actions/actionTypes";
import { initialState } from "./initialState";

function nextGuessId(guesses) {
  var maxId = 0;
  if (guesses?.length > 0)
    maxId = guesses.reduce((maxId, guess) => Math.max(guess.id, maxId), -1);
  return maxId + 1;
}

function evaluateGuess(guess, guessdigit, guesses) {
  const guessdigitstring = JSON.stringify(guessdigit);
  const guessarray = guess.split("");
  var countNumbers = 0;
  var countPlaces = 0;
  var fVictory = false;
  for (var i = 0; i < guessdigit.length; i++) {
    if (guessdigitstring.includes(guessarray[i]))
      countNumbers = countNumbers + 1;
    if (guessarray[i] === guessdigit[i].toString())
      countPlaces = countPlaces + 1;
    if (countPlaces === 4) fVictory = true;
  }
  var allguesses = [...guesses];
  allguesses[guesses.length] = {
    id: nextGuessId(allguesses),
    value: guess,
    result: { numbers: countNumbers, places: countPlaces, victory: fVictory },
  };
  window.localStorage.setItem("saved-guesses", JSON.stringify(allguesses));
  if (fVictory) {
    window.localStorage.setItem("victory", fVictory);
  }
  return { numbers: countNumbers, places: countPlaces, victory: fVictory };
}

export default function guessReducer(state = initialState.guesses, action) {
  switch (action.type) {
    case types.ENTER_GUESS:
      return [
        ...state,
        {
          id: nextGuessId(state),
          value: action.guess.value,
          result: evaluateGuess(action.guess.value, action.guessdigit, state),
        },
      ];
    default:
      return state;
  }
}
